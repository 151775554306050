import * as React from "react";
import { Link, Route, Switch } from "react-router-dom";

const Home = () => {
    return <div className={"box"}>
        <div className={"logo"}>
            <span className={"logo-icon"}/>
            <span className={"logo-name"}>Ezera</span>
        </div>
    </div>
}

const NotFound = () => {
    return <div className={"not-found"}>
        <div>404</div>
        <Link className={"home-link"} to={"/"}>home</Link>
    </div>
}

class App extends React.Component {

    render() {

        return <div className="App">
            <Switch>
                <Route exact path="/" component={Home}/>
                <Route path="**" component={NotFound}/>
            </Switch>
        </div>
    }


}

export default App;
